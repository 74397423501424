<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-select
          :dropdownMatchSelectWidth="false"
          @select="changeParamsVersion"
          v-if="type == 2"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 150px"
          v-model="params.versionId"
          placeholder="请选择版别"
        >
          <a-select-option
            v-for="items of notConfirmVersionList"
            :key="items.versionId"
            :value="items.versionId"
            >{{ items.versionName }}</a-select-option
          >
        </a-select>
        <a-input
          allowClear
          v-if="type != 2"
          v-model="params.product_name"
          size="small"
          style="width: 120px; margin-right: 10px"
          placeholder="商品标题"
        ></a-input>
        <a-input
          allowClear
          v-if="type != 2"
          v-model="params.code"
          size="small"
          style="width: 120px; margin-right: 10px"
          placeholder="盒子编号"
        ></a-input>
        <a-input
          allowClear
          v-if="type != 2"
          v-model="params.service_name"
          size="small"
          style="width: 120px; margin-right: 10px"
          placeholder="录入人员"
        ></a-input>
        <!-- <a-select allowClear v-if="type!=2" size='small' style="width:150px;margin:5px;"  v-model="params.reviewStatus" placeholder='状态'>
                    <a-select-option :value='1'>已确认</a-select-option>
                    <a-select-option :value='0'>未确认</a-select-option>
                </a-select> -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          v-if="type != 2"
          size="small"
          style="width: 120px; margin: 5px"
          v-model="params.whiteCoinStatus"
          placeholder="白币"
        >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
        <a-select
          :dropdownMatchSelectWidth="false"
          v-if="type != 2"
          @select="changeVersionlistSearch"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 150px"
          v-model="params.kindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="items of bigList"
            :key="items.kindId"
            :value="items.kindId"
            >{{ items.kindName }}</a-select-option
          >
        </a-select>
        <a-select
          :dropdownMatchSelectWidth="false"
          v-if="type != 2"
          @select="selectVersionSearch"
          allowClear
          @search="getVersitionListSearch"
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 120px"
          v-model="params.versionId"
          placeholder="请选择版别"
        >
          <a-select-option
            v-for="items of smallList"
            :key="items.versionId"
            :value="items.versionId"
            >{{ items.versionName }}</a-select-option
          >
        </a-select>
        <a-select
          :dropdownMatchSelectWidth="false"
          v-if="type != 2"
          @select="changeGoodsType"
          allowClear
          size="small"
          style="margin: 5px; width: 120px"
          v-model="params.goodsType"
          placeholder="评级公司"
        >
          <a-select-option :value="1">PCGS</a-select-option>
          <a-select-option :value="2">公博</a-select-option>
        </a-select>
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="getData"
          allowClear
          size="small"
          style="margin: 5px; min-width: 120px"
          v-model="params.scoreId"
          placeholder="评分"
        >
          <a-select-option
            v-for="item of searchRanks"
            :key="item.id"
            :value="item.id"
            >{{ item.scoreName }}</a-select-option
          >
        </a-select>

        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeSource"
          allowClear
          size="small"
          style="width: 150px; margin: 5px"
          v-model="params.goodsSource"
          placeholder="数据源"
        >
          <a-select-option :value="1">HX</a-select-option>
          <a-select-option :value="3">WPT</a-select-option>
          <a-select-option :value="4">XZ</a-select-option>
          <a-select-option :value="2">QBTT</a-select-option>
        </a-select>

        <a-range-picker
          v-model="times"
          :placeholder="['开始时间', '结束时间']"
          size="small"
          style="margin: 5px"
          @change="onChange"
        />
        <a-button @click="getData(2)" size="small" style="margin-left: 10px"
          >搜索</a-button
        >

        <a-popconfirm
          title="Are you sure confirm this item?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="batchSet(selctedArrays, 1)"
          v-if="type == 2"
        >
          <a-button :disabled="showDis" size="small" style="margin-left: 10px"
            >白币确认</a-button
          >
        </a-popconfirm>
        <a-popconfirm
          title="Are you sure confirm this item?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="batchSet(selctedArrays, 2)"
          v-if="type == 2"
        >
          <a-button :disabled="showDis" size="small" style="margin-left: 10px"
            >非白币确认</a-button
          >
        </a-popconfirm>
      </div>
    </div>
    <div class="table-content">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :rowKey="(record, index) => index"
      >
        <template slot="price">
          <div style="display: flex; align-items: center">
            <div style="width: 50px">成交价</div>
            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <a-icon
                :style="params.sort == 1 ? 'color:#1890ff' : ''"
                @click="sortPrice(1)"
                type="caret-up"
              />
              <a-icon
                :style="params.sort == -1 ? 'color:#1890ff' : ''"
                @click="sortPrice(-1)"
                type="caret-down"
              />
            </div>
          </div>
        </template>
        <template slot="time">
          <div style="display: flex; align-items: center">
            <div style="width: 50px">成交时间</div>
            <div
              style="display: flex; flex-direction: column; align-items: center"
              v-if="type == 2"
            >
              <a-icon
                :style="params.timeSort == 2 ? 'color:#1890ff' : ''"
                @click="sortPrice(2, 2)"
                type="caret-up"
              />
              <a-icon
                :style="params.timeSort == 1 ? 'color:#1890ff' : ''"
                @click="sortPrice(1, 2)"
                type="caret-down"
              />
            </div>
          </div>
        </template>
        <div slot="Images" slot-scope="row" style="width: 50px; height: 50px">
          <img
            @click="previewImg(row)"
            style="max-width: 50px; max-height: 50px"
            :src="row.coverUrl"
            alt=""
          />
        </div>
        <div
          slot="setup"
          slot-scope="row"
          style="display: flex; flex-direction: column"
        >
          <div style="display: flex">
            <a-popconfirm
              title="Are you sure confirm this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="batchSet([{ id: row.id }], 2)"
              v-if="type == 2"
            >
              <a-button style="margin-right: 5px" type="primary" size="small"
                >非白币确认</a-button
              >
            </a-popconfirm>
            <a-popconfirm
              title="Are you sure confirm this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="batchSet([{ id: row.id }], 1)"
              v-if="type == 2"
            >
              <a-button type="primary" size="small">白币确认</a-button>
            </a-popconfirm>
          </div>

          <div style="margin-top: 5px; display: flex">
            <a-button
              size="small"
              @click="openModal(row)"
              style="margin-right: 5px"
              >编辑</a-button
            >
            <a-button
              size="small"
              @click="getCoverUrls(row)"
              style="margin-right: 5px"
              >更新封面</a-button
            >
            <a-popconfirm
              title="Are you sure delete this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="remove(row.id)"
            >
              <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
          </div>
        </div>
      </a-table>
    </div>

    <a-modal
      v-drag-modal
      v-model="visible"
      title="编辑商品"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="ID">
          <a-input disabled size="small" v-model="forms.id"></a-input>
        </a-form-model-item>
        <a-form-model-item label="大币种" prop="kindId">
          <a-select
            @change="changeVersionlist"
            allowClear
            showSearch
            size="small"
            v-model="forms.kindId"
            placeholder="请选择大币种"
          >
            <a-select-option
              v-for="items of kinds"
              :key="items.kindId"
              :value="items.kindId"
              >{{ items.kindName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="版别" prop="versionId">
          <a-select
            @select="selectVersion"
            allowClear
            @search="getVersitions"
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            v-model="forms.versionId"
            placeholder="请选择版别"
          >
            <a-select-option
              v-for="items of versions"
              :key="items.versionId"
              :value="items.versionId"
              >{{ items.versionName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="分数" prop="scoreId">
          <a-select
            size="small"
            v-model="forms.scoreId"
            placeholder="请选择评分"
          >
            <a-select-option
              v-for="item of ranks"
              :key="item.id"
              :value="item.id"
              >{{ item.scoreName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="价格">
          <a-input
            allowClear
            size="small"
            placeholder="请输入价格"
            v-model="forms.price"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="白币">
          <a-select
            size="small"
            v-model="forms.whiteCoinStatus"
            placeholder="请选择"
          >
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="封面图">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  data() {
    return {
      columns: [
        { title: "id", dataIndex: "id" },
        { title: "编号", dataIndex: "pcgsNumber" },
        {
          title: "原商品标题",
          customRender: (text, row, index) => {
            return <span style="color:#1890ff">{row.title}</span>;
          },
        },

        { title: "封面图", scopedSlots: { customRender: "Images" } },
        { title: "大币种-版别", dataIndex: "versionName" },
        {
          title: "来源",
          customRender: (text, row, index) => {
            return (
              <span>
                {row.goodsSource == 1
                  ? "HX"
                  : row.goodsSource == 2
                  ? "QBTT"
                  : row.goodsSource == 3
                  ? "WPT"
                  : "XZ"}
              </span>
            );
          },
        },
        {
          key: "time",
          scopedSlots: { customRender: "time", title: "time" },
          customRender: (text, row, index) => {
            return <span>{row.time}</span>;
          },
        },
        { title: "评级", dataIndex: "grade" },
        { title: "分数", dataIndex: "score" },
        {
          key: "action",
          scopedSlots: { customRender: "action", title: "price" },
          customRender: (text, row, index) => {
            return <span style="color:red">{row.price}</span>;
          },
        },
        {
          title: "对比",
          customRender: (text, row, index) => {
            return <span style="color:red">{row.coinWhite}</span>;
          },
        },
        //  {title:'状态',customRender:(text, row, index) => {
        //         const inner = row.reviewStatus == 1 ? <span style='color:green'>已确认</span>  : <span style='color:red'>未确认</span>
        //         return inner;
        //     }},
        { title: "采集时间", dataIndex: "updateTime" },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
          width: 200,
        },
      ],
      data: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      forms: {},
      rules: {
        kindId: [
          { required: true, message: "请选择大币种", trigger: "change" },
        ],
        versionId: [
          { required: true, message: "请选择版别", trigger: "change" },
        ],
        scoreId: [{ required: true, message: "请选择分数", trigger: "change" }],
      },
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 20,
        sort: 0,
        reviewStatus: 1,
        goodsType: this.$route.query.goodsType,
      },
      visible: false,
      kinds: [],
      versions: [],
      ranks: [],
      imgKey: "",
      fileList: [],
      previewVisible: false,
      previewImage: "",
      navagitorEdit: false,
      type: 1,
      smallList: [],
      ranks: [],
      searchRanks: [],
      notConfirmVersionList: [],
      selectedRowKeys: [],
      selctedArrays: [],
      bigList: [],
      smallList: [],
      type_list: [
        { name: "PCGS", value: 1 },
        { name: "公博", value: 2 },
      ],
      times: [],
    };
  },
  computed: {
    ...mapState(["ip"]),
    showDis() {
      let inner;
      if (this.selectedRowKeys.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },

  created() {
    const { id, type, goodsType } = this.$route.query;
    if (id) {
      this.params.id = id;
      this.params.goodsType = goodsType;
      this.navagitorEdit = true;
    }
    this.type = type;
    this.params.type = type;
    this.params.reviewStatus = this.type == 2 ? 0 : 1;
    this.getConfirmVersions();
    this.getKinds();
    this.getData();
    this.getSelectList();
  },
  watch: {
    visible() {
      this.imgKey = this.visible ? "" : Math.random();
    },
    $route(to, from) {
      const { type, goodsType } = to.query;
      this.params = {
        pageNum: 1,
        pageSize: 20,
        sort: 0,
        reviewStatus: 1,
        goodsType: this.$route.query.goodsType,
        type: type,
      };
      this.type = type;
      this.params.goodsType = goodsType;
      if (type == 2) {
        this.params.reviewStatus = 0;
      } else {
        this.params.reviewStatus = 1;
      }
      this.params.startTime = "";
      this.params.endTime = "";
      this.times = [];
      this.getData(this.params);
      this.getConfirmVersions();
    },
  },
  methods: {
    async getData(type = 1) {
      if (type == 2) {
        this.params.pageNum = 1;
        this.pagination.current = 1;
      }
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/list",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status == 200) {
        const { data } = res;
        const { records } = data;
        this.pagination.total = res.data.total;
        this.data = records;
        this.selectedRowKeys = [];
        this.selctedArrays = [];
      }
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData();
    },
    async openModal(row) {
      const params = JSON.parse(JSON.stringify(row));
      const { versionId, kindId } = params;
      if (params.reviewStatus != 1) {
        params.whiteCoinStatus = 1;
      }
      const { images } = params || { images: [] };
      if (images && images.length > 0) {
        this.fileList = images.map((items, i) => {
          const obj = {
            uid: i,
            name: "image",
            status: "done",
            url: items,
          };
          return obj;
        });
      }
      this.forms = params;
      if (kindId != null && kindId != "") {
        await this.getVersitions("");
        this.getRateListForVersionId(versionId);
      }
      this.visible = true;
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    resetForms() {
      this.fileList = [];
    },
    async submit(forms, type) {
      let params = JSON.parse(JSON.stringify(forms));
      try {
        const images = this.fileList.map((row) => {
          return row.url;
        });
        params.coverUrl = images.join(",");
      } catch (e) {}
      if (type == 2) {
        params = {
          id: forms.id,
          whiteCoinStatus: 0,
          type: 1,
        };
      }
      const res = await this.axios.post(
        "/dq_admin/coinSilverVersionGoods/management/edit",
        params
      );
      if (res.status == 200) {
        this.$message.success(res.message || "编辑成功");
        this.visible = false;
        this.fileList = [];
        // if(this.navagitorEdit){

        //     window.postMessage({
        //         msg:'refsh',
        //         key:'dq_charts_reload'
        //     },'*')
        // }
        this.getData();
      }
    },
    async getKinds() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status == 200) {
        const { data } = res;
        this.kinds = data;
      }
    },
    async getVersitions(str) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { keyword: str, kindId: this.forms.kindId || "" },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        this.versions = data;
      }
    },
    async getRateListForVersionId(versionId, type) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getScreenScoreList",
        {
          params:
            type == 2
              ? {
                  versionId,
                  goodsType: this.$route.query.goodsType,
                  type: this.params.type,
                  startTime: this.params.startTime,
                  endTime: this.params.endTime,
                  goodsSource: this.params.goodsSource,
                }
              : { versionId, type: this.params.type },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        if (type == 2) {
          this.searchRanks = data;
        } else {
          this.ranks = data;
        }
      }
    },
    selectVersionSearch(id){
     
      delete this.params.scoreId
       if (!this.params.goodsType) {
        delete this.params.goodsType
      }
      this.getRateListForVersionId(id);
      this.getRateListForVersionId(this.params.versionId, 2);
    },
    selectVersion(id) { 
      this.forms.scoreId = null;
      this.getRateListForVersionId(id);
      this.getRateListForVersionId(this.params.versionId, 2);
    },
    async changeVersionlist() {
      this.getVersitions("");
    },
    async remove(id) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/del",
        {
          params: { id: id },
        }
      );
      if (res.status == 200) {
        this.$message.success(res.message || "删除成功");
        const index = this.data.findIndex((el) => {
          return id == el.id;
        });
        this.data.splice(index, 1);
      }
    },
    previewImg(row) {
      if (row.images && row.images.length > 0) {
        this.$previewImg({
          list: row.images.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },

    async getCoverUrls(row) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/editCover",
        { params: { goodsId: row.id } }
      );
      this.$loading.hide();
      if (res.status == 200) {
        this.$message.success(res.message || "获取成功");
        this.getData();
      }
    },
    onChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.startTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.endTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
      this.getConfirmVersions();
      this.params.versionId = "";
      this.$set(this.params, "scoreId", "");
      if (!this.params.versionId) {
        return false;
      }
      this.getRateListForVersionId(this.params.versionId, 2);
    },
    async getVersitionList(str) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { keyword: str },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        this.smallList = data;
      }
    },
    // selectVersion(id){
    //     this.$set(this.params,'scores',null)
    //     this.getRateListForVersionId(id)
    // },
    // async getRateListForVersionId(versionId){
    //     const res = await this.axios('/dq_admin/coinSilverVersionGoods/getKindVersionAndScoreByPcgsCodeOrVersionId',{
    //         params:{versionId}
    //     })
    //     if(res.status == 200){
    //         const {scoreList} = res.data;
    //         this.ranks = scoreList;
    //     }
    // },
    async batchSet(array, type) {
      if (!Array.isArray && array.length < 1) {
        return this.$message.warning("请选择");
      }
      const ids = array.map((row) => row.id);
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/batchConfirmation",
        { params: { silverGoodsIds: ids.join(","), type: type } }
      );
      if (res.status == 200) {
        this.$message.success(res.message || "请求成功");
        ids.forEach((el) => {
          const index = this.data.findIndex((el2) => {
            return el == el2.id;
          });
          this.data.splice(index, 1);
        });
        this.selectedRowKeys = [];
        this.selctedArrays = [];
      }
    },
    sortPrice(s, type = 1) {
      type == 1 ? (this.params.sort = s) : (this.params.timeSort = s);
      if (type == 2) {
        this.params.sort = null;
      } else {
        this.params.timeSort = null;
      }
      this.getData();
    },
    async getConfirmVersions() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getScreenList",
        {
          params: {
            startTime: this.params.startTime,
            endTime: this.params.endTime,
            goodsType: this.params.goodsType,
            goodsSource: this.params.goodsSource,
          },
        }
      );
      if (res.status == 200) {
        this.notConfirmVersionList = res.data;
      }
    },
    changeParamsVersion(e) {
      this.$set(this.params, "scoreId", "");
      this.getRateListForVersionId(e, 2);
      this.getData();
    },
    onSelectChange(selectedRowKeys, selctedArrays) {
      this.selectedRowKeys = selectedRowKeys;
      this.selctedArrays = selctedArrays;
    },
    changeGoodsType(e) {
      this.$set(this.params, "scoreId", null);
      this.getRateListForVersionId(this.params.versionId, 2);
      this.getData();
    },
    async changeVersionlistSearch() {
      this.params.versionId = null;
      this.params.goodsType = null;
      this.params.scores = [];
      await this.getVersitionListSearch("");
    },
    async getVersitionListSearch(str) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { keyword: str, kindId: this.params.kindId },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        this.smallList = data;
      }
    },
    async getSelectList() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status == 200) {
        const { data } = res;
        this.bigList = data;
      }
    },
    changeSource() {
      this.$set(this.params, "scoreId", "");
      this.params.versionId = "";
      this.getConfirmVersions();
      this.getRateListForVersionId(this.params.versionId, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>