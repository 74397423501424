var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _vm.type == 2
              ? _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "150px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      allowClear: "",
                      showSearch: "",
                      "filter-option": _vm.untils.filterOption,
                      size: "small",
                      placeholder: "请选择版别"
                    },
                    on: { select: _vm.changeParamsVersion },
                    model: {
                      value: _vm.params.versionId,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "versionId", $$v)
                      },
                      expression: "params.versionId"
                    }
                  },
                  _vm._l(_vm.notConfirmVersionList, function(items) {
                    return _c(
                      "a-select-option",
                      {
                        key: items.versionId,
                        attrs: { value: items.versionId }
                      },
                      [_vm._v(_vm._s(items.versionName))]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.type != 2
              ? _c("a-input", {
                  staticStyle: { width: "120px", "margin-right": "10px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "商品标题"
                  },
                  model: {
                    value: _vm.params.product_name,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "product_name", $$v)
                    },
                    expression: "params.product_name"
                  }
                })
              : _vm._e(),
            _vm.type != 2
              ? _c("a-input", {
                  staticStyle: { width: "120px", "margin-right": "10px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "盒子编号"
                  },
                  model: {
                    value: _vm.params.code,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "code", $$v)
                    },
                    expression: "params.code"
                  }
                })
              : _vm._e(),
            _vm.type != 2
              ? _c("a-input", {
                  staticStyle: { width: "120px", "margin-right": "10px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "录入人员"
                  },
                  model: {
                    value: _vm.params.service_name,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "service_name", $$v)
                    },
                    expression: "params.service_name"
                  }
                })
              : _vm._e(),
            _vm.type != 2
              ? _c(
                  "a-select",
                  {
                    staticStyle: { width: "120px", margin: "5px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      allowClear: "",
                      size: "small",
                      placeholder: "白币"
                    },
                    model: {
                      value: _vm.params.whiteCoinStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "whiteCoinStatus", $$v)
                      },
                      expression: "params.whiteCoinStatus"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: 1 } }, [
                      _vm._v("是")
                    ]),
                    _c("a-select-option", { attrs: { value: 0 } }, [
                      _vm._v("否")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.type != 2
              ? _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "150px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      allowClear: "",
                      showSearch: "",
                      size: "small",
                      placeholder: "请选择大币种"
                    },
                    on: { select: _vm.changeVersionlistSearch },
                    model: {
                      value: _vm.params.kindId,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "kindId", $$v)
                      },
                      expression: "params.kindId"
                    }
                  },
                  _vm._l(_vm.bigList, function(items) {
                    return _c(
                      "a-select-option",
                      { key: items.kindId, attrs: { value: items.kindId } },
                      [_vm._v(_vm._s(items.kindName))]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.type != 2
              ? _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "120px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      allowClear: "",
                      showSearch: "",
                      "filter-option": _vm.untils.filterOption,
                      size: "small",
                      placeholder: "请选择版别"
                    },
                    on: {
                      select: _vm.selectVersionSearch,
                      search: _vm.getVersitionListSearch
                    },
                    model: {
                      value: _vm.params.versionId,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "versionId", $$v)
                      },
                      expression: "params.versionId"
                    }
                  },
                  _vm._l(_vm.smallList, function(items) {
                    return _c(
                      "a-select-option",
                      {
                        key: items.versionId,
                        attrs: { value: items.versionId }
                      },
                      [_vm._v(_vm._s(items.versionName))]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.type != 2
              ? _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "120px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      allowClear: "",
                      size: "small",
                      placeholder: "评级公司"
                    },
                    on: { select: _vm.changeGoodsType },
                    model: {
                      value: _vm.params.goodsType,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "goodsType", $$v)
                      },
                      expression: "params.goodsType"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: 1 } }, [
                      _vm._v("PCGS")
                    ]),
                    _c("a-select-option", { attrs: { value: 2 } }, [
                      _vm._v("公博")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", "min-width": "120px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  allowClear: "",
                  size: "small",
                  placeholder: "评分"
                },
                on: { change: _vm.getData },
                model: {
                  value: _vm.params.scoreId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "scoreId", $$v)
                  },
                  expression: "params.scoreId"
                }
              },
              _vm._l(_vm.searchRanks, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.scoreName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  allowClear: "",
                  size: "small",
                  placeholder: "数据源"
                },
                on: { change: _vm.changeSource },
                model: {
                  value: _vm.params.goodsSource,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "goodsSource", $$v)
                  },
                  expression: "params.goodsSource"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("HX")]),
                _c("a-select-option", { attrs: { value: 3 } }, [_vm._v("WPT")]),
                _c("a-select-option", { attrs: { value: 4 } }, [_vm._v("XZ")]),
                _c("a-select-option", { attrs: { value: 2 } }, [_vm._v("QBTT")])
              ],
              1
            ),
            _c("a-range-picker", {
              staticStyle: { margin: "5px" },
              attrs: { placeholder: ["开始时间", "结束时间"], size: "small" },
              on: { change: _vm.onChange },
              model: {
                value: _vm.times,
                callback: function($$v) {
                  _vm.times = $$v
                },
                expression: "times"
              }
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.getData(2)
                  }
                }
              },
              [_vm._v("搜索")]
            ),
            _vm.type == 2
              ? _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: "Are you sure confirm this item?",
                      "ok-text": "Yes",
                      "cancel-text": "No"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.batchSet(_vm.selctedArrays, 1)
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { disabled: _vm.showDis, size: "small" }
                      },
                      [_vm._v("白币确认")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.type == 2
              ? _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: "Are you sure confirm this item?",
                      "ok-text": "Yes",
                      "cancel-text": "No"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.batchSet(_vm.selctedArrays, 2)
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { disabled: _vm.showDis, size: "small" }
                      },
                      [_vm._v("非白币确认")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c(
            "a-table",
            {
              attrs: {
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onSelectChange
                },
                pagination: _vm.pagination,
                loading: _vm.loading,
                columns: _vm.columns,
                "data-source": _vm.data,
                rowKey: function(record, index) {
                  return index
                }
              },
              on: { change: _vm.changePage },
              scopedSlots: _vm._u([
                {
                  key: "Images",
                  fn: function(row) {
                    return _c(
                      "div",
                      { staticStyle: { width: "50px", height: "50px" } },
                      [
                        _c("img", {
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px"
                          },
                          attrs: { src: row.coverUrl, alt: "" },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(row)
                            }
                          }
                        })
                      ]
                    )
                  }
                },
                {
                  key: "setup",
                  fn: function(row) {
                    return _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _vm.type == 2
                              ? _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "Are you sure confirm this item?",
                                      "ok-text": "Yes",
                                      "cancel-text": "No"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.batchSet([{ id: row.id }], 2)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small"
                                        }
                                      },
                                      [_vm._v("非白币确认")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.type == 2
                              ? _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "Are you sure confirm this item?",
                                      "ok-text": "Yes",
                                      "cancel-text": "No"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.batchSet([{ id: row.id }], 1)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small"
                                        }
                                      },
                                      [_vm._v("白币确认")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "5px",
                              display: "flex"
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.openModal(row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.getCoverUrls(row)
                                  }
                                }
                              },
                              [_vm._v("更新封面")]
                            ),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "Are you sure delete this item?",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.remove(row.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  { attrs: { size: "small", type: "danger" } },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }
                }
              ])
            },
            [
              _c("template", { slot: "price" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", { staticStyle: { width: "50px" } }, [
                      _vm._v("成交价")
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("a-icon", {
                          style: _vm.params.sort == 1 ? "color:#1890ff" : "",
                          attrs: { type: "caret-up" },
                          on: {
                            click: function($event) {
                              return _vm.sortPrice(1)
                            }
                          }
                        }),
                        _c("a-icon", {
                          style: _vm.params.sort == -1 ? "color:#1890ff" : "",
                          attrs: { type: "caret-down" },
                          on: {
                            click: function($event) {
                              return _vm.sortPrice(-1)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("template", { slot: "time" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", { staticStyle: { width: "50px" } }, [
                      _vm._v("成交时间")
                    ]),
                    _vm.type == 2
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("a-icon", {
                              style:
                                _vm.params.timeSort == 2 ? "color:#1890ff" : "",
                              attrs: { type: "caret-up" },
                              on: {
                                click: function($event) {
                                  return _vm.sortPrice(2, 2)
                                }
                              }
                            }),
                            _c("a-icon", {
                              style:
                                _vm.params.timeSort == 1 ? "color:#1890ff" : "",
                              attrs: { type: "caret-down" },
                              on: {
                                click: function($event) {
                                  return _vm.sortPrice(1, 2)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          attrs: {
            title: "编辑商品",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "ID" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", size: "small" },
                    model: {
                      value: _vm.forms.id,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "id", $$v)
                      },
                      expression: "forms.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种", prop: "kindId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        size: "small",
                        placeholder: "请选择大币种"
                      },
                      on: { change: _vm.changeVersionlist },
                      model: {
                        value: _vm.forms.kindId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "kindId", $$v)
                        },
                        expression: "forms.kindId"
                      }
                    },
                    _vm._l(_vm.kinds, function(items) {
                      return _c(
                        "a-select-option",
                        { key: items.kindId, attrs: { value: items.kindId } },
                        [_vm._v(_vm._s(items.kindName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别", prop: "versionId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        "filter-option": _vm.untils.filterOption,
                        size: "small",
                        placeholder: "请选择版别"
                      },
                      on: {
                        select: _vm.selectVersion,
                        search: _vm.getVersitions
                      },
                      model: {
                        value: _vm.forms.versionId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "versionId", $$v)
                        },
                        expression: "forms.versionId"
                      }
                    },
                    _vm._l(_vm.versions, function(items) {
                      return _c(
                        "a-select-option",
                        {
                          key: items.versionId,
                          attrs: { value: items.versionId }
                        },
                        [_vm._v(_vm._s(items.versionName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "分数", prop: "scoreId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { size: "small", placeholder: "请选择评分" },
                      model: {
                        value: _vm.forms.scoreId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "scoreId", $$v)
                        },
                        expression: "forms.scoreId"
                      }
                    },
                    _vm._l(_vm.ranks, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.scoreName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "价格" } },
                [
                  _c("a-input", {
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "请输入价格"
                    },
                    model: {
                      value: _vm.forms.price,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "price", $$v)
                      },
                      expression: "forms.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "白币" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { size: "small", placeholder: "请选择" },
                      model: {
                        value: _vm.forms.whiteCoinStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "whiteCoinStatus", $$v)
                        },
                        expression: "forms.whiteCoinStatus"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("是")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("否")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "封面图" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileList
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChange
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }